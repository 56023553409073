<template>
  <div class="page">
    <el-popover
        placement="bottom-end"
        width="600"
        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
        v-model="visible">
        <el-form label-width="70px" label-position="left">
          <el-form-item label="搜索方式">
            <el-input v-model="searchObj[`${searchObj.searchWay}`]">
              <el-select style="width: 100px" v-model="searchObj.searchWay" slot="prepend" placeholder="请选择">
                <el-option v-for="(item,index) in searchParams['list-search-field']" :label="index" :value="item"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-date-picker
                v-model="searchObj.time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="售后分类">
            <el-select v-model="searchObj.kind" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['kind-list']" :label="item" :value="index"></el-option>
            </el-select>
          </el-form-item>
          <div style="display: flex;flex-direction: row;justify-content: center">
            <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
            <el-button size="mini" @click="initSearch">重置</el-button>
          </div>
        </el-form>
      <el-button slot="reference">筛选</el-button>
    </el-popover>
    <el-button style="margin-left: 10px" type="primary" @click="addAfterSale">新增</el-button>
    <el-tabs style="margin-top: 10px" v-model="searchObj.state" type="card"  @tab-click="handleTabsClick">
      <el-tab-pane label="全部订单" name="0"></el-tab-pane>
      <el-tab-pane name="1">
        <template slot="label" >
          <span>申请维权</span>
          <span class="count" v-if="summary['1']">({{ summary['1'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="2">
        <template slot="label">
          <span>卖家拒绝</span>
          <span class="count" v-if="summary['2']">({{ summary['2'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="3">
        <template slot="label">
          <span>买家取消</span>
          <span class="count" v-if="summary['3']">({{ summary['3'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="4">
        <template slot="label">
          <span>买家已收货</span>
          <span class="count" v-if="summary['4']">({{ summary['4'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="5">
        <template slot="label">
          <span>卖家已收货</span>
          <span class="count" v-if="summary['5']">({{ summary['5'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="6">
        <template slot="label">
          <span>买家待退货</span>
          <span class="count" v-if="summary['6']">({{ summary['6'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="7">
        <template slot="label">
          <span>待商家发货</span>
          <span class="count" v-if="summary['7']">({{ summary['7'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="8">
        <template slot="label">
          <span>待转账</span>
          <span class="count" v-if="summary['8']">({{ summary['8'] }})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="9">
        <template slot="label">
          <span>维权结束</span>
          <span class="count" v-if="summary['9']">({{ summary['9'] }})</span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <afterSalelistTable :orderList="list" :canChoosed="false"/>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>

<script>
import afterSalelistTable from "@/views/afterSale/component/afterSalelistTable";
import {apiGetSupportList, apiGetSupportMix} from "@/request/api";
import {mapState, mapMutations} from 'vuex'
export default {
  name: "afterSale",
  components: {
    afterSalelistTable
  },
  data() {
    return {
      visible: false,
      pickerOptions:{
        shortcuts:[
          {
            text:'24小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近3天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      pageCount: 0,
      searchObj: {},
      searchParams:{},
      list: [],
      summary:{}
    };
  },
  computed: {
    ...mapState([
      'refresh'
    ]),
  },
  activated() {
    if (this.refresh) {
      this.initSearch()
      this.getData()
      this.reset(false);
    }
  },
  created() {
    this.getSearchParams()
    this.initSearch()
    this.getData()
  },
  methods:{
    ...mapMutations({
      reset: 'setRefresh'
    }),
    addAfterSale(){
      this.$router.push({
        name: 'afterSale',
        query: {
          orderId: '',
          kind:'create'
        }
      });
    },
    getSearchParams(){
      apiGetSupportMix({mix :'list-search-field|kind-list'}).then(res => {
        if(res.code == 200){
          this.searchParams = res.data;
        }
      })
    },
    handleTabsClick(){
      this.searchObj.page = 1
      this.getData()
    },
    pageChangeHandle(){
      this.getData()
    },
    onSearch(){
      this.searchObj.page = 1
      this.getData()
    },
    getData(){
      let data = {
        page:this.searchObj.page,
        'per-page':this.searchObj['per-page'],
        kind : this.searchObj.kind,
        create_at_start:this.searchObj.time[0] || '',
        create_at_end:this.searchObj.time[1] || '',
        state:this.searchObj.state,
      }
      if(this.searchObj.searchWay){
        data[`${this.searchObj.searchWay}`] = this.searchObj[`${this.searchObj.searchWay}`];
      }
      apiGetSupportList(data).then(res => {
        if(res.code == 200){
          this.pageCount = res.data.count;
          this.list = res.data.list;
          this.summary = res.data.summary
        }
      })

    },
    initSearch(){
      this.searchObj = {
        searchWay: '',
        time: [],
        kind: '2',
        state:'0',
        create_at_start:'',
        create_at_end:'',

        page:1,
        'per-page':10,
      }
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.count {
  color:#F56C6C;
}
.pageView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.el-form-item{
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>